.footer {
    // background: #004b96;
    background: #fff;
    padding: 12px 0;
    font-weight: 300;
    position: relative;

    .footer-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;

        .img {
            width: 100px;
            height: 100px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .arrow {
        position: fixed;
        bottom: 30px;
        right: 30px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fade 2s infinite ease-in-out;
        cursor: pointer;
        z-index: 100;
        // background: #fff;
        background: linear-gradient(to right, #004b96, rgba(37, 154, 97, 1));
        color: #fff;
        font-size: 42px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // border: 2px solid #004b96;
    }

    @keyframes fade {
        0% {
            bottom: 30px;
        }

        50% {
            bottom: 40px;
        }

        100% {
            bottom: 30px;
        }
    }

    .footerPara {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        p {
            color: #4f4f4f;
            margin: 0;
            padding: 12px 0px 0 0px;
            font-size: 14px;
            font-weight: 400;

            span {
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: #004b96;
                }
            }
        }

    }

    // Top Footer
    .topFooter {
        padding: 10px 50px;
        border-bottom: 1px solid #4f4f4f;

        .topFooterRow {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;

            .topFooterCol {
                // padding: 16px 0;
                width: 100px;
                height: 200px;
                position: relative;
                
                &:first-child {
                    width: 180px;
                }

                &:nth-child(2) {
                    width: 230px;
                }

                &:nth-child(3) {
                    width: 280px;
                }

                &:last-child {
                    width: 320px;
                }

                h1 {
                    margin: 10px 0;
                    color: #4f4f4f;
                    font-weight: 700;
                    font-size: 14px;
                    text-transform: uppercase;
                }

                ul {
                    padding: 0;
                    position: absolute;

                    li {
                        list-style: none;

                        .nav-link {
                            color: #4f4f4f !important;
                            font-size: 14px;
                            font-weight: 400;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }


                    .nav-link {
                        position: relative;
                        color: #4f4f4f;
                        font-size: 14px;
                        font-weight: 400;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .nav-link span {
                        display: none;
                        position: absolute;
                        width: 140px;
                        border-radius: 8px;
                        top: 100%;
                        left: 40px;
                        background: #f4f4f4;
                        box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.3);
                        z-index: 1;
                    }

                    .nav-link:hover span {
                        display: block;
                    }

                }
            }
        }

        .topFooterIcons {
            // position: fixed;
            // bottom: 120px;
            // left: 0;
            // width: 40px;
            // height: 130px;
            display: flex;
            align-items: center;
            // justify-content: center;
            gap: 16px;
            // flex-direction: column;
            cursor: pointer;
            z-index: 100;
            // background: #004b96;

            .icons {
                font-size: 28px;
                color: #4f4f4f;
                cursor: pointer;
                margin: 6px 0;

                &:hover {
                    color: #2b2b2b;
                }
            }

        }

    }
}

@media only screen and (max-width:768px) {
    .arrow {
        bottom: 20px;
        right: 20px;
        width: 40px !important;
        height: 40px !important;
    }
}