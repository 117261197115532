.dataBox {
    width: 200px;
    height: 180px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
    position: relative;
    background: #004b96;
  
    .icon {
      text-align: center;
      margin: 16px;
      width: 60px;
  
      img {
        width: 100%;
        height: 100%;
        filter: invert(1);
      }
    }
  
    .dataBox-body {
      text-align: center;
  
      p {
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        text-transform: uppercase;
        margin: 16px;
      }
    }
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      transform: translateX(-100%);
      transition: transform 0.5s ease-in-out;
      z-index: -1;
    }
  
    &:hover:before {
      transform: translateX(0);
    }
  
    &:hover .icon img {
      filter: invert(0);
    }
  
    &:hover .dataBox-body p {
      color: #000;
    }
  
    .icon, .dataBox-body {
      position: relative;
      z-index: 1;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .dataBox {
      width: 100px;
      height: 84px;
  
      .icon {
        margin: 6px;
        width: 40px;
      }
  
      .dataBox-body p {
        font-size: 13px;
        margin: 6px;
        line-height: normal;
      }
    }
  }
  