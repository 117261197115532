.pdf {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 1000px;
    padding: 40px 30px;
    margin: 18px 0;

    .iframe {
        height: 100%;
        width: 100%;
    }
}

@media only screen and (max-width:1000px) {

    .pdf {
        height: 800px;
        padding: 40px 4px;
        margin: 10px 0;
    }

}