.careers {
    background: #fff;
    margin-top: 48px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;

    .careers-main {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 32px 0;

        .careers-headings {
            font-size: 48px;
            // color: #15a0c7;
            // color: #004b96;
            font-weight: 700;
            text-transform: uppercase;
            width: 100%;
            background: linear-gradient(#004b96, rgba(37, 154, 97, 1));
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;

            span {
                font-size: 54px;
            }
        }

        .btn {
            border: 2px solid #004b96;
            color: #004b96;
            padding: 14px 28px;
            background: transparent;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                background: #004b96;
                color: #fff;
                transform: scale(1.1);
                box-shadow: 0 8px 15px rgba(0, 123, 255, 0);
            }
        }
    }

    .leads {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        color: #000;
        margin-top: 32px;
        
        .background-image {
            width: 100%;
            height: 100%;
            
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        
        .leads-text {
            width: 100%;
            text-align: left;

            h1 {
                font-size: 32px !important;
                font-weight: bold;
            }

            p {
                font-size: 16px;
            }
        }
    }

}

@media only screen and (max-width:768px) {
    .careers {
        padding: 30px 8px !important;

        .careers-main {
            flex-direction: column;
        }
        .leads {
            flex-direction: column;
            margin-top: 16px;
        }
    }
}

@media only screen and (max-width:625px) {

    .careers {
        padding: 30px 8px !important;

        .careers-headings {
            font-size: 32px;
            font-weight: 700;

            span {
                font-size: 36px;
            }
        }
    }
}