.navbar {
    // background: #15a0c7;
    // background: #004b96;
    background: #fff;


    .navbar-brand {
        border-radius: 50%;
        height: 80px;
        margin: 2px;
        padding: 2px;
        width: 80px;
        z-index: 1;
        background: #fff;

        img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
        }
    }

    .navbar-toggler {
        z-index: 10;
        border: 2px solid #4f4f4f !important;
    }

    .navbar-collapse {
        // background: #004b96;
        // position: absolute;
        // top: 90px;
        // left: 0;
        // width: 100%;
        // transition: 0.3s;
        // border-top: 1px solid #fff;
        // border-bottom-right-radius: 150px;
        // text-align: left;
        // padding: 0 0 0 100px;

        .navbar-nav {
            // width: 250px;
            // padding: 12px;

            .nav-item {
                font-size: 13px;
                color: #4f4f4f;

                .nav-link {
                    color: #4f4f4f;
                    font-size: 14px;
                    position: relative;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                        color: #004b96;
                    }
                }

                span {
                    display: none;
                    position: absolute;
                    width: 210px;
                    // bottom: -80px;
                    // bottom: -50px;
                    top: 150px;
                    right: 100px;
                    // background: #0167cc;
                    background: #fff;
                    z-index: 1;
                    padding: 4px 12px;
                }

                &:hover span {
                    display: block;
                }

                .contact-btn {
                    color: #ffffff !important;
                    background: #004b96;
                    border-radius: 100px;
                    padding: 8px 18px;
                    font-size: 14px;
                    position: relative;
                    font-weight: 500;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .nav-icons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 140px;

        .nav-link {
            color: #ffffff;
            margin: 0 10px;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                color: #000;
            }


        }
    }
}

.search-icon {
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .popup-header {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 800px;
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        input {
            flex: 1;
            border: none;
            font-size: 16px;
            padding: 10px;
            outline: none;
        }

        .close-btn {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    .popup-results {
        width: 100%;
        max-width: 800px;
        margin-top: 20px;
        overflow-y: auto;

        li {
            cursor: pointer;
            transition: background 0.3s;

            &:hover {
                background: #f8f9fa;
            }
        }
    }
}


@media only screen and (max-width:1080px) {
    // .navbar-toggler {
    //     margin-right: 150px !important;
    // }

    // .search-icon {
    //     right: 18px;
    // }
}

@media only screen and (max-width:878px) {

    .nav-icons {
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .navbar-collapse {
        padding: 0 0 0 12px !important;

        .navbar-nav {
            padding: 8px 0 !important;

            .nav-item {

                span {
                    top: 94% !important;
                    left: 100px !important;
                }
            }
        }
    }

    // .navbar-toggler {
    //     margin-right: 120px !important;
    // }

    // .search-icon {
    //     position: absolute;
    //     right: 24px;
    // }

}

.custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    .navbar-toggler-icon {
        margin-right: 180px !important;
    }
}

.custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    top: -10px;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
    top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0;
    margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
    display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin: 0;
    margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
    -webkit-transform: none;
    transform: none;
}