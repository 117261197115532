.products {
    padding: 30px;

    h1{
        width: 50%;
    }

    .table {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }

    .comp {
        width: 300px;
    }

    input{
        width: 300px;
        // height: 100%;
    }
}

@media only screen and (max-width:1000px) {

    .products {
       
        h1{
            width: 100% !important;
        }
    }

}