.home {

    .carousel {
        cursor: pointer;

        .img {
            background: #000;
            width: 100%;
            height: 90vh;
            z-index: -1;
            background-image: radial-gradient(#242424 1px, transparent 1px);
            background-size: 18px 18px;

            img {
                width: 100%;
                height: 100%;
                opacity: 0.8;
            }
        }

        .slider_section {
            padding: 100px 0;

            .detail-box {
                h1 {
                    font-weight: 700;
                    font-size: 100px;
                    font-family: 'ITC Eras Demi';
                }

                // p {
                //     position: absolute;
                //     color: #000;
                //     bottom: 0;
                //     font-size: 24px;
                //     font-weight: 500;
                //     font-family: 'ITC Eras Demi';
                //     width: 100%;
                //     background: #ffffffcb;
                // }
            }

        }
    }

    .dataBoxs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 90%;
        padding: 30px 0;
    }

    // Exports
    .exports {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .exportText {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            width: 80%;

            h1 {
                color: #004b96;
                font-weight: 700;
                font-size: 32px;
            }

            p {
                font-weight: 350;
                font-size: 20px;
                color: #535353;
                padding: 0;
                // text-transform: uppercase;
            }
        }

        .map-section {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px 0;

            // .map-box{
            //     width: 40%;
            // }
            .map-img {
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    // collaborations
    .collaborations {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 40px 0;

        .collaborationsDetail {
            // background: #f8f8f8;
            // background: #004b96;
            width: 80%;
            text-align: center;

            .collaborationsContent {
                background: rgb(0, 75, 150);
                background: linear-gradient(315deg, rgba(0, 75, 150, 1) 21%, rgba(37, 154, 97, 1) 100%);
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h3 {
                    // color: #3db5e6;
                    color: #c4eeff;
                    font-weight: 300;
                    margin: 3px 0;
                    padding: 0;
                }

                h1 {
                    color: #fff;
                    font-weight: 700;
                }

                p {
                    font-weight: 350;
                    font-size: 20px;
                    // color: #535353;
                    color: #dbdbdb;
                    margin: 24px 0;
                    padding: 0;
                    width: 50%;
                }

                // .btn {
                //     border-radius: 0;
                //     padding: 15px 30px;
                //     text-transform: uppercase;
                //     font-size: 12px;
                //     font-weight: 400;
                //     background: #3db5e6;
                //     border: none;
                //     color: #fff;

                //     &:hover {
                //         background: #03a8e9;
                //     }
                // }
            }

            .clients-box {
                display: flex;
                align-items: center;
                justify-content: center;
                // flex-wrap: wrap;
                // padding: 30px 0;
                // background: #a5a5a5;
                // background: #f0f4f9;

                .customers {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    width: 50%;
                    height: 100%;

                    h1 {
                        font-size: 120px;
                        text-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
                        /* Downward shadow */
                        background: linear-gradient(to bottom, #004b96, rgba(37, 154, 97, 1));
                        /* Gradient on top */
                        -webkit-text-fill-color: transparent;
                        /* Transparent text for the gradient to show */
                        -webkit-background-clip: text;
                        /* Clip the gradient to the text */
                        position: relative;
                    }

                    p {
                        width: 54%;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: start;
                        color: #4f4f4f;
                        text-transform: uppercase;
                    }
                }

                .clients {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    padding: 30px 0;
                    // background: #a5a5a5;
                    background: #f0f4f9;
                    width: 50%;

                    .img {
                        width: 60px;
                        margin: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                            // filter: grayscale(1);
                            // cursor: pointer;

                            // &:hover{
                            //     filter: grayscale(0);
                            // }
                        }
                    }
                }
            }
        }
    }

    // News
    .news {
        margin: 40px 0;
        position: relative;
        display: flex;
        height: 680px;


        .cards {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            overflow: hidden;
            position: absolute;
            width: 100%;
            bottom: 10px;
            right: 0;
        }

        .newsDetail {
            width: 100%;
            text-align: center;

            .newsContent {
                padding: 40px;
                display: flex;
                flex-direction: column;

                h3 {
                    color: #3db5e6;
                    font-weight: 300;
                    margin: 3px 0;
                    padding: 0;
                }

                h1 {
                    color: #004b96;
                    font-weight: 700;
                    width: 100% !important;
                }
            }
        }
    }

    @media only screen and (max-width:768px) {

        .carousel {
            // margin-top: 84px;

            .img {
                width: 100%;
                height: auto !important;
            }

            .slider_section {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .detail-box {
                    position: absolute;
                    top: 50%;

                    h1 {
                        font-weight: 500;
                        font-size: 32px;
                    }

                    // p {
                    //     font-size: 13px;
                    //     font-weight: 300;
                    //     bottom: -24px;
                    // }
                }

            }
        }

        .dataBoxs {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 90%;
            padding: 10px 0;
        }

        .collaborations {
            margin: 10px 0;

            .collaborationsDetail {
                width: 90%;

                .collaborationsContent {
                    padding: 10px 0;

                    h1 {
                        font-size: 24px;
                    }

                    p {
                        font-weight: 300;
                        font-size: 18px;
                        margin: 12px 0;
                        width: 100% !important;
                    }

                }

                .clients-box {
                    flex-direction: column;

                    .customers {
                        width: 100%;

                        h1 {
                            font-size: 100px;
                        }

                        p {
                            text-align: center;
                        }
                    }

                    .clients {
                        width: 100%;

                        .img {
                            width: 60px;
                            margin: 8px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .news {
            margin: 0;
            height: 660px;
        }

        // Exports
        .exports {
            padding: 20px 0;

            .exportText {
                width: 96%;


                h1 {
                    font-size: 24px;

                }

                p {
                    font-size: 16px;
                    font-weight: 300;
                }
            }

            .map-section {
                width: 96%;
                padding: 12px;
            }
        }

    }

}