.cards {
    width: 100%;
    // margin: auto;
}

.cards-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}

.cardBox {
    flex: 1 0 400px;
    // background: #f0f4f9;
    margin: 10px;
    overflow: hidden;
    border-radius: 6px;
    transition: all 250ms ease-in-out;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
        transform: scale(1.05);
    }


    .card-content {
        padding: 0 0 32px 0;

        h2 {
            font-size: 18px;
            color: #000;
            margin: 0 10px;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            // background: #fff;
            // padding: 2px;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            clip-path: polygon(100% 0%, 0% 0%, 0.00% 89.01%, 2.50% 89.03%, 5.00% 89.00%, 7.50% 88.91%, 10.00% 88.76%, 12.50% 88.56%, 15.00% 88.31%, 17.50% 88.00%, 20.00% 87.65%, 22.50% 87.25%, 25.00% 86.81%, 27.50% 86.34%, 30.00% 85.83%, 32.50% 85.29%, 35.00% 84.72%, 37.50% 84.14%, 40.00% 83.55%, 42.50% 82.94%, 45.00% 82.33%, 47.50% 81.73%, 50.00% 81.13%, 52.50% 80.54%, 55.00% 79.98%, 57.50% 79.43%, 60.00% 78.92%, 62.50% 78.43%, 65.00% 77.99%, 67.50% 77.58%, 70.00% 77.22%, 72.50% 76.91%, 75.00% 76.64%, 77.50% 76.43%, 80.00% 76.27%, 82.50% 76.17%, 85.00% 76.13%, 87.50% 76.14%, 90.00% 76.22%, 92.50% 76.34%, 95.00% 76.53%, 97.50% 76.76%, 100.00% 77.05%);
        }

        p {
            margin: 6px 10px 10px 10px;
            font-weight: 300;
            text-align: justify;
        }

        .btn {
            position: absolute;
            bottom: 0px;
            right: 10px;
            // padding: 8px 12px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            // background: #004b96;
            background: none;
            border: none;
            color: #004b96;
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 4px;


            &:hover {
                // background: #005bb6;
                text-decoration: underline;
            }
        }

        .linkedinBtn {
            position: absolute;
            bottom: 0;
            left: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            background: none;
            border: none;
            color: #004b96;
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 4px;
        }
    }
}

@media screen and (max-width: 625px) {
    .container {
        width: 100% !important;
    }

    .cards-container {
        padding: .5rem !important;
    }

    .cardBox {
        flex: 1 0 330px !important;
    }

    .card-content {
        padding: 0, 0, 16px, 0 !important;

        p {
            margin: 6px 10px 10px 10px !important;

        }
    }
}