.blog {
    padding: 30px;
    background: #fff;

    .blogBox {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .blogData {
            position: relative;
            display: flex;
        }
    }
}

@media screen and (max-width: 625px) {
    .blog {
        padding: 30px 0px !important;
    }
}