.jobBox {
    width: 100%;
    padding: 30px;

    .jobs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        width: 100%;

        .video {
            width: 42%;
        }

        .jobDetail {
            width: 42%;

            .jobContent {
                padding: 40px;
                display: flex;
                flex-direction: column;

                h3 {
                    color: #3db5e6;
                    font-weight: 300;
                    padding: 0;
                    font-size: 21px;
                }

                h1 {
                    color: #004b96;
                    font-weight: 700;
                    font-size: 32px;
                }

                p {
                    font-weight: 350;
                    font-size: 20px;
                    color: #535353;
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .jobBox {
        padding: 30px;

        .jobs {
            flex-direction: column !important;

            .video {
                width: 100%;
                height: 200px;
            }

            .jobDetail {
                width: 90%;

                .jobContent {
                    padding: 10px;
                }
            }
        }

    }
}