.mainHeadings {
    font-size: 32px;
    // color: #15a0c7;
    color: #004b96;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    margin: 30px 0;

    &:after {
        content: '';
        width: 100px;
        height: 4px;
        border-radius: 4px;
        // background: #15a0c7;
        background: #004b96;
        display: block;
        transition: 2s;
        margin: 12px 0;
        animation: line 2s ease-in-out;
    }

    @keyframes line {
        0% {
            width: 0px;
        }

        100% {
            width: 100px;
        }
    }
}


@media only screen and (max-width:500px) {
    .mainHeadings {
        font-size: 24px;
        font-weight: 700;
    }
}

.gradientHeadings {
    // background: -webkit-linear-gradient( rgba(0, 75, 150, 1), rgba(37, 154, 97, 1));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #004b96, rgba(37, 154, 97, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.privacy-policy-headings {
    color: #004b96;
    font-size: 24px;
    font-weight: 700;
}

.animation-inner {
    position: relative;
    width: 100%;
    height: 100%;
    // margin-top: 30px;

    #wave {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        svg {
            z-index: 1000;
        }
    }
}

// Animation
.animation-box {
    position: relative;
    width: 100%;
    height: 120px;
    margin-top: -100px;
    z-index: 100;
}

.animation-box:before {
    position: absolute;
    content: '';
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.7);
    filter: blur(0);
    opacity: 1;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
}

// .animation-box:hover:before {
//     transform: translate(-50%, -50%) scale(1);
//     filter: blur(50px);
// }

.animation-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.animation-box:before,
.animation-box-inner {
    background-image:
        url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='100%25' y2='-10.959%25'%3e%3cstop stop-color='%23259A61' stop-opacity='.75' offset='0%25'/%3e%3cstop stop-color='%23004B96' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
        url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='100%25' y2='-10.959%25'%3e%3cstop stop-color='%23259A61' stop-opacity='.75' offset='0%25'/%3e%3cstop stop-color='%23004B96' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
        url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='100%25' y2='-10.959%25'%3e%3cstop stop-color='%23259A61' stop-opacity='.75' offset='0%25'/%3e%3cstop stop-color='%23004B96' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e");
    background-repeat: repeat-x;
    background-size: 1600px 50%;
    background-position: 0 130%, -50px 130%, 500px 130%;
    animation: 20s waves linear infinite forwards;
}


@keyframes waves {
    to {
        background-position: 1600px 130%, 3150px 130%, 5300px 130%;
    }
}