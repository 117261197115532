.feedback-button {
    position: fixed;
    z-index: 100;
    bottom: 25%; // Adjust the position of the button along the right side
    right: 0;
    transform: translateY(50%);
    background-color: #f0f0f0; // Equivalent to bg-blue-700
    color: #000;
    border-radius: 0;
    padding: 0.5rem 0.6rem;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    writing-mode: vertical-rl; // Rotates text to display vertically
    text-align: center; // Center text alignment
    height: auto;
    width: auto;
    white-space: nowrap; // Prevent text wrapping

    &:hover {
        background-color: #f0f0f0; // Hover effect (bg-blue-600)
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        color: #004b96;
    }

    .rotate {
        text-transform: uppercase;
        display: inline-block;
        transform: rotate(-180deg);
    }
}

.feedback-form {
    z-index: 100;
    width: 300px;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    position: relative;
    margin: 0px 0px -100px -48px;
}