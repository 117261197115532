.pages {
    background: #000;
    width: 100%;
    height: 70vh;
    position: relative;
    // margin-top: 50px;
    z-index: -2;
    background-image: radial-gradient(#181818 1px, transparent 1px);
    background-size: 18px 18px;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        opacity: 0.8;
        z-index: -1;
        object-fit: cover;
    }

    .pages-detail {
        position: absolute;
        // width: 430px;
        bottom: -50px;
        background: #004b96;
        z-index: 10;

        h1 {
            margin: 18px;
            text-align: center;
            font-size: 52px;
            font-weight: 700;
            color: #fff;
        }
    }
}

@media only screen and (max-width:1000px) {
    .pages {
        height: auto !important;

        .pages-detail {
            position: absolute;
            // width: 320px;
            bottom: -36px;
            background: #004b96;
            z-index: 10;
    
            h1 {
                margin: 12px;
                text-align: center;
                font-size: 32px;
                font-weight: 700;
                color: #fff;
            }
        }
    }
}