.contact {

    .contact-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // background: #f5f5f5;
        background: #fff;

        .map {
            width: 100%;
            // padding-top: 70px;
        }

        h1 {
            font-size: 36px;
            width: 80%;
            padding: 0;
            margin-top: 30px;
        }

        .contact-box {
            padding-bottom: 18px;
            width: 80%;
            display: flex;

            .address {
                width: 40%;
                padding: 10px;
                // background: #15a0c7;
                background: #004b96;

                h1 {
                    font-size: 24px;
                    color: #fff;

                    &:after {
                        background: #fff;
                    }
                }

                p {
                    font-size: 18px;
                    color: #fff;
                    padding: 0;
                    font-weight: 300;
                    display: flex;
                    align-items: center;

                    span {
                        padding: 0 10px;
                        font-size: 16px !important;
                        width: 260px;
                    }

                }

                .contactIcon {
                    border-radius: 50px;
                    background: #7eccff6e;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                // .footerIcons {
                //     display: flex;
                //     align-items: center;
                //     justify-content: space-between;
                //     width: 280px;

                //     .icons {
                //         font-size: 21px;
                //         color: #fff;
                //         cursor: pointer;

                //         &:hover {
                //             color: #000;
                //         }
                //     }
                // }
            }

            .contactForm {
                padding: 30px;
                // background: #fff;
                background: #f8f8f8;

                h1 {
                    font-size: 24px;
                }

                form {
                    padding: 50px 0;

                    .form-control {
                        outline: none;
                        box-shadow: none;
                        // border: 1px solid #15a0c7;
                        border: 1px solid #004b96;
                        border-radius: 0;
                        padding: 8px;
                        margin: 12px 0;
                        background: #f8f8f8;

                        &::placeholder {
                            color: #9c9c9c;
                            font-size: 13px;
                        }
                    }

                    .btn {
                        // background: #15a0c7;
                        background: #004b96;
                        border: none;
                        border-radius: 0;
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: uppercase;
                        color: #fff;
                        padding: 16px 32px;
                        margin-top: 20px;
                        width: 200px;

                        &:hover {
                            background: #0057ad;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1000px) {

    .contact-details {
        h1 {
            width: 90% !important;
        }

        .contact-box {
            flex-direction: column;
            width: 90% !important;

            .address {
                width: 100% !important;
            }
        }
    }

}