.api {
    background: #fff;

    .api-text {
        padding: 80px 30px 0 30px;

        .apiTextBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .para {
                font-size: 22px;
                font-weight: 300;
                padding-right: 30px;
                width: 60%;

                p {
                    margin: 24px 0;
                    font-size: 18px;
                    text-align: justify;
                }

                &>:nth-child(2) {
                    font-weight: 400;
                }
            }

            .apiImg {
                width: 40%;
                // height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .api-details {
        width: 100%;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;

        .api-table {
            margin: 50px 0;

            h1 {
                font-size: 28px;
            }

            .table {
                width: 100%;
                border-top: 1px solid #15a0c7;
                border-bottom: 1px solid #15a0c7;

                th,
                td {
                    background: none !important;
                    text-align: center;
                }

                td {
                    width: 250px;
                }
            }
        }

        .question {
            padding: 150px 0;
            width: 340px;

            input,
            textarea {
                background: none;
                border: 1px solid #004b96;
            }

            input[type="text"] {
                margin-bottom: -1px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            input[type="email"] {
                margin-bottom: -1px;
                border-radius: 0;
                border-radius: 0;
            }

            textarea {
                margin-bottom: -1px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }

            .btn {
                margin-top: 20px;
                width: 50%;
                border: none;
                border-radius: 0;
                background: #004b96;
                color: #fff;

                &:hover {
                    background: #0058af;
                }
            }
        }

        .quriesForm {
            padding-top: 18px;

            p {
                font-size: 18px;
                color: #000;
                padding: 0;
                font-weight: 300;
                display: flex;
                align-items: center;

                span {
                    padding: 0 10px;
                    font-size: 16px !important;
                    width: 260px;
                }

            }

            .contactIcon {
                border-radius: 50px;
                background: #004b96;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }

    }

    @media only screen and (max-width:1000px) {
        .api-text {
            padding: 24px 12px 0 12px;

            .apiTextBox {
                flex-direction: column;

                .para {
                    width: 100% !important;
                    padding: 0 !important;
                }

                .apiImg {
                    width: 100% !important;
                }
            }
        }

        .api-details {
            flex-direction: column;

            .api-table {
                width: 100%;
            }

            .question {
                padding: 18px 0 !important;
                width: 330px;
            }
        }

    }
}