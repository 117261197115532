.about {
    // background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .aboutBox {
        padding: 30px;

        .aboutDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0;

            p {
                font-weight: 350;
                font-size: 18px;
                color: #535353;
                width: 100%;
                margin: 24px 0;
                text-align: justify;
            }

            .learnBtn {
                cursor: pointer;
                color: #004b96;
                text-decoration: underline;
                font-size: 12px;
            }

            .img {
                width: 50%;
                // height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .company-video {
        width: 100%;

        .video {
            width: 80%;
        }
    }
}

@media only screen and (max-width:1000px) {

    .aboutBox{
        padding: 10px !important;

        .aboutDetails {
            flex-direction: column;

            p {
                padding: 0;
                font-size: 15px !important;
            }

            div {
                width: 100% !important;
            }


        }
    }

}