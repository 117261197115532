.blogPage {
    padding: 100px 40px;
    background: #fff;

    h1 {
        font-size: 28px;
        width: 100% !important;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        // text-align: justify;
        color: #000;

        h4 {
            margin: 0;
            color: #3db5e6;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 18px;
            text-align: start;
            width: 100% !important;
        }
    }
}

@media only screen and (max-width:768px) {
    .blogPage {

        h1 {
            font-size: 24px;
        }
    }

}