.numberBox {
    // width: 280px;
    // height: 247.5px;
    // background: #f8f8f8;
    // background: #004b96;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // &:hover {
    //     box-shadow: rgba(120, 120, 120, 0.2) -5px 5px 12px, rgba(120, 120, 120, 0.1) -10px 10px 12px, rgba(120, 120, 120, 0.05) -15px 15px 12px;
    // }

    .icon {
        // text-align: center;
        // width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        padding: 16px;
        border-radius: 50%;
        color: #fff !important;
        background: linear-gradient(to right, #004b96, rgba(37, 154, 97, 1)) // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }

    .dataBox-body {
        text-align: center;

        p {
            color: #000;
            font-size: 18px;
            line-height: 20px;
            font-weight: 400;
            text-transform: uppercase;
            // margin: 20px;
        }
    }

    .dataBox-number {
        text-align: center;
        display: flex;
        font-size: 42px;
        color: #004b96;
        font-weight: 700;
    }
}


@media only screen and (max-width:1200px) {
    .dataBox {
        // width: 200px;
        // height: 180px;

        // .icon {
        //     margin: 16px;
        //     width: 60px;
        // }

        .dataBox-body {
            text-align: center;

            p {
                font-size: 16px;
                line-height: normal;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .dataBox {
        // width: 100px;
        // height: 84px;

        // .icon {
        //     margin: 6px;
        //     width: 40px;
        // }

        .dataBox-body {
            text-align: center;

            p {
                font-size: 14px;
                line-height: normal;
            }
        }
    }
}